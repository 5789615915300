import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClickListenerService {
  private clickObservable: Observable<any>;

  constructor() {
    this.clickObservable = fromEvent(document, 'click');
  }

  getClickObservable(): Observable<any> {
    return this.clickObservable;
  }
}
