import { Pipe, PipeTransform } from '@angular/core';
import { Templates, UtilsService } from '@horizon/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getInnerGridModalTitle',
})
export class GetInnerGridModalTitlePipe implements PipeTransform {
  constructor(
    private utils: UtilsService,
    public translate: TranslateService
  ) {}

  transform(
    gridTemplate: string,
    gridParent: string,
    templates: Templates
  ): string {
    if (templates?.[gridParent]) {
      const found = templates[gridParent].fields.find(
        (field) =>
          field?.type === 'oneToMany' &&
          this.utils.toCamelCase(field?.reference?.template) === gridTemplate
      );
      if (found) {
        return found.displayName[this.translate.currentLang];
      }
    }
  }
}
