import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getInnerGridModalContext',
})
export class GetInnerGridModalContextPipe implements PipeTransform {
  constructor() {}

  transform(parent: string, name: string, itemsByTemplates: any): any {
    if (itemsByTemplates?.[parent]) {
      let context = {
        parent: parent,
        parentId: itemsByTemplates[parent].id,
        parentFieldName: name,
      };
      return context;
    }
  }
}
