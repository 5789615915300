import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { HttpClient } from '@angular/common/http';
import {
  Item,
  ItemContext,
  ReferenceContext,
  Template,
} from '@horizon/dm-core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DynamicApiService {
  public baseUrl: UrlBuilder = new UrlBuilder(environment.apim.baseUrl)
    .addPath(environment.apim.apiSlugs.dynamicModelApi)
    .addPathVariable('/');

  constructor(private http: HttpClient) {}

  public getTemplate(templates: string[]): Promise<Template | Template[]> {
    const options = {
      params: { name: templates },
    };
    const fullUrl: string = this.baseUrl.addPath('Template').build();
    try {
      return this.http.get<Template>(fullUrl, options).toPromise();
    } catch (error) {}
  }

  public getProcessTemplate(
    templates: string[]
  ): Promise<Template | Template[]> {
    const options = {
      params: { name: templates },
    };
    const fullUrl: string = this.baseUrl
      .addPath('Template')
      .addPath('processTemplate')
      .build();
    try {
      return this.http.get<Template>(fullUrl, options).toPromise();
    } catch (error) {}
  }

  public async getChildTemplatesForParent(parentTemplates: any): Promise<any> {
    let childTemplates = {};
    Object.keys(parentTemplates).map((template) => {
      const childFields = parentTemplates[template].fields.filter(
        (_) => _.type === 'child'
      );
      if (childFields?.length) {
        childFields.map(async (child) => {
          childTemplates[child.name.toLowerCase()] = this.getTemplate(
            child.name.toLowerCase()
          );
        });
      }
    });
    return childTemplates;
  }

  public getItem(template: string, id: number): Promise<Item> {
    const fullUrl = this.baseUrl
      .addPath('Item')
      .addPathVariable(template?.toString())
      .addPathVariable(id?.toString())
      .build();
    return this.http.get<Item>(fullUrl).toPromise();
  }

  public getItems(context: ItemContext): Promise<Item> {
    const fullUrl = this.baseUrl
      .addPath('Item')
      .addPathVariable(context.parent?.toString())
      .addPathVariable(context.parentId?.toString())
      .addPathVariable(context.parentFieldName?.toString())
      .build();
    return this.http.get<Item>(fullUrl).toPromise();
  }

  public getReference(context: ReferenceContext): Promise<Item[]> {
    const fullUrl = this.baseUrl
      .addPath('Item')
      .addPath('reference')
      .addPathVariable(context.parent?.toString())
      .addPathVariable(context.referenceName?.toString())
      .build();
    return this.http.get<Item[]>(fullUrl).toPromise();
  }

  public saveItem(item: Item): Promise<object> {
    const fullUrl = this.baseUrl.addPath('Item').build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public searchItem(templateName: string, searchValue: any): Promise<any> {
    const fullUrl = this.baseUrl.addPath('Item').addPath('search').build();
    const assetSearch = {
      template: templateName,
      fields: searchValue,
    };
    return this.http.post(fullUrl, assetSearch).toPromise();
  }

  public searchItemByIds(
    templateName: string,
    ids: any,
    searchValue: any
  ): Promise<object> {
    const fullUrl = this.baseUrl.addPath('Item').addPath('search').build();
    const assetSearch = {
      template: templateName,
      ids: ids,
      fields: searchValue,
    };
    return this.http.post(fullUrl, assetSearch).toPromise();
  }

  public getHistory(
    schemaName: string,
    tableName: string,
    keyIds: number[],
    columns: object
  ): Promise<any> {
    const fullUrl = this.baseUrl
      .addPath('internal')
      .addPath('VerticalLog')
      .addPath('Log')
      .build();
    const historySearch = {
      schemaName,
      tableName,
      keyIds,
      columns,
    };
    return this.http.post<any[]>(fullUrl, historySearch).toPromise();
  }
}
