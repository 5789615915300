import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatCellElement',
})
export class FormatCellElementPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {}

  transform(value: any): any {
    const checkDate = new Date(value);
    if (typeof value === 'string' && checkDate.getMonth()) {
      return this.datePipe.transform(value, 'dd/MM/yyy');
    } else {
      return value?.[this.translate.currentLang]
        ? value[this.translate.currentLang]
        : value;
    }
  }
}
