import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ArrangementRequest } from '@shared/interfaces/Arrangement';
import { AccountChange } from '@shared/interfaces/AccountChange';

@Injectable({
  providedIn: 'root',
})
export class LoanEngineApiService {
  public baseUrl: UrlBuilder = new UrlBuilder(environment.apim.baseUrl)
    .addPath(environment.apim.apiSlugs.loanEngineApi)
    .addPathVariable('/');

  public baseUrlLoanEngine: UrlBuilder = new UrlBuilder(
    environment.apim.baseUrl
  )
    .addPath(environment.apim.apiSlugs.loanEngine)
    .addPathVariable('/');

  constructor(private http: HttpClient) {}

  public getDebtTypes(accountId: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('debtType')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public getAccountSummary(accountId: number): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .build();
    return this.http.get<any>(fullUrl).toPromise();
  }

  public getQuotations(accountId: number): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('account')
      .addPath(accountId?.toString())
      .build();
    return this.http.get<any>(fullUrl).toPromise();
  }

  public getQuotationsTypes(accountId: number): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('types')
      .build();
    return this.http.get<any>(fullUrl).toPromise();
  }

  public getQuotationById(quoteId: number): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath(quoteId?.toString())
      .build();
    return this.http.get<any>(fullUrl).toPromise();
  }

  public getQuotationCharges(accountId: number): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('charges')
      .build();
    return this.http.get<any>(fullUrl).toPromise();
  }

  public generateFullQuotationPreview(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('full')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public saveFullQuotation(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('full')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public executeFullQuotation(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('full')
      .addPath('execute')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public generatePartialQuotationPreview(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('partial')
      .addPath('preview ')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public savePartialQuotation(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('partial')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public executePartialQuotation(quoteId: number, item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('quotation')
      .addPath('partial')
      .addPath(quoteId?.toString())
      .addPath('execute')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public reverseTransaction(item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('reverse ')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public getTransactionCodes(): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('codes')
      .build();
    return this.http.get<string[]>(fullUrl).toPromise();
  }

  public getTransactionsById(id: number) {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('account')
      .addPath(id?.toString())
      .addPath('codes')
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getTransactionPreview(accountId: number, item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public saveTransaction(accountId: number, item: any): Promise<object> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('account')
      .addPath(accountId?.toString())
      .build();
    return this.http.post(fullUrl, item).toPromise();
  }

  public getTransactions(
    accountId: number,
    debtType: string | undefined
  ): Observable<any> {
    let fullUrl: string;
    if (debtType) {
      fullUrl = this.baseUrl
        .addPath('transaction')
        .addPath('account')
        .addPath(accountId?.toString())
        .addQueryParam('debtType', debtType?.toString())
        .build();
    } else {
      fullUrl = this.baseUrl
        .addPath('transaction')
        .addPath('account')
        .addPath(accountId?.toString())
        .build();
    }

    return this.http.get<any>(fullUrl);
  }

  public getSystemDate(): Promise<any> {
    const fullUrl: string = this.baseUrl
      .addPath('config')
      .addPath('systemDate')
      .build();
    return this.http.get<string[]>(fullUrl).toPromise();
  }

  public getAccountHistory(accountId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('edit')
      .addPath('history')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public getAccountChangeInputs(accountId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('edit')
      .addPath('inputs')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public evaluateAccountChanged(
    accountId: number,
    data: any
  ): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('edit')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, data);
  }

  public saveAccountChanged(
    accountId: number,
    data: AccountChange
  ): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('edit')
      .build();
    return this.http.post(fullUrl, data);
  }

  public getArrangementsToPay(accountId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('arrangement')
      .addPath('account')
      .addPath(accountId?.toString())

      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public getArrangementsData(arrangementId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('arrangement')
      .addPath(arrangementId?.toString())

      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public arrangementsToPayPreview(data: ArrangementRequest): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('arrangement')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, data);
  }

  public saveArrangementsToPay(data: ArrangementRequest): Observable<any> {
    const fullUrl: string = this.baseUrl.addPath('arrangement').build();
    return this.http.post(fullUrl, data);
  }

  public cancelArrangementsToPay(id: number, reason: string): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('arrangement')
      .addPath(id?.toString())
      .addPath('cancel')
      .addQueryParam('reason', reason)
      .build();
    return this.http.put(fullUrl, {});
  }

  public getBaseRates(): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('config')
      .addPath('baseRates')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public setBaseRate(item: any): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('config')
      .addPath('baseRate')
      .build();
    return this.http.post(fullUrl, item);
  }

  public getSwitchableProducts(accountId: number): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('switchableProducts')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public switchProductPreview(
    accountId: number,
    effectiveDate: string,
    productCode: string
  ): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('switchProduct')
      .addPath('preview')
      .build();

    return this.http.post<any>(fullUrl, { effectiveDate, productCode });
  }

  public switchProduct(
    accountId: number,
    effectiveDate: string,
    productCode: string
  ): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('switchProduct')
      .build();

    return this.http.post<any>(fullUrl, { effectiveDate, productCode });
  }

  public switchProductHistory(accountId: number): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('switchProduct')
      .addPath('history')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public getTransactionCodesGrid(): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('transactionCodes')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public getTariff(): Observable<any> {
    const fullUrl = this.baseUrl.addPath('config').addPath('tariffs').build();

    return this.http.get<any>(fullUrl);
  }

  public getTariffById(idTariffValue: number): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('tariff')
      .addPath(idTariffValue?.toString())
      .addPath('excludedFees')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public getDebtTypesModal(): Observable<any> {
    const fullUrl = this.baseUrl.addPath('config').addPath('debtTypes').build();

    return this.http.get<any>(fullUrl);
  }

  public getWaterfallTypes(): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('waterfallTypes')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public getTransactionCodeGroups(): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('transactionCodeGroups')
      .build();

    return this.http.get<any>(fullUrl);
  }

  public newTransactionCode(data: {
    codeDescription: string;
    codeGroup: string;
    waterfallType: string;
    debtTypeName: string;
  }): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('transactionCode/new')
      .addQueryParam('transactionCode', data)
      .build();
    return this.http.post<any>(fullUrl, data);
  }

  public editTransactionCode(id: number): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('transactionCode/deactivate')
      .addPath(id?.toString())
      .build();
    return this.http.post<any>(fullUrl, id?.toString());
  }

  public dueDayChangePreview(
    id: number,
    newDueDay: number,
    payStubInterest: boolean
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(id.toString())
      .addPath('changeDueDate')
      .addPath('preview')
      .build();

    const body = {
      newDueDay: newDueDay,
      payStubInterest: payStubInterest,
    };

    return this.http.post<any>(fullUrl, body);
  }

  public dueDayChange(
    id: number,
    newDueDay: number,
    payStubInterest: boolean
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(id.toString())
      .addPath('changeDueDate')
      .build();

    const body = {
      newDueDay: newDueDay,
      payStubInterest: payStubInterest,
    };

    return this.http.post<any>(fullUrl, body);
  }

  public updateFee(data: {
    tariffId: number;
    feeId: number;
    amount: number;
    active: boolean;
  }): Observable<any> {
    const fullUrl = this.baseUrl
      .addPath('config')
      .addPath('tariff/updateFee')
      .addQueryParam('updateFee', data)
      .build();
    return this.http.post<any>(fullUrl, data);
  }

  public getRateHistory(accountId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('rateHistory')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public getInterestChargeHistory(accountId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('interestChargeHistory')
      .build();
    return this.http.get<string[]>(fullUrl);
  }

  public verticalLogData(item: any): Observable<any> {
    const fullUrl: string = this.baseUrlLoanEngine
      .addPath('internal')
      .addPath('VerticalLog')
      .addPath('Log')
      .build();
    return this.http.post<any>(fullUrl, item);
  }

  public previewBorrowback(accountId: number, data: any): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('borrowback')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, data);
  }

  public approveBorrowback(accountId: number, data: any): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('borrowback')
      .build();
    return this.http.post(fullUrl, data);
  }

  public previewUnderpayment(accountId: number, data: any): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('underpayment')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, data);
  }

  public approveUnderpayment(accountId: number, data: any): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('underpayment')
      .build();
    return this.http.post(fullUrl, data);
  }

  public cancelUnderpayment(accountId: number, data: any): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('underpayment')
      .addPath('cancel')
      .build();
    return this.http.post(fullUrl, data);
  }

  public previewPaymentHoliday(
    accountId: number,
    data: any
  ): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('paymentHoliday')
      .addPath('preview')
      .build();
    return this.http.post(fullUrl, data);
  }

  public approvePaymentHoliday(
    accountId: number,
    data: any
  ): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('paymentHoliday')
      .build();
    return this.http.post(fullUrl, data);
  }

  public cancelPaymentHoliday(
    accountId: number,
    data: any
  ): Observable<object> {
    const fullUrl: string = this.baseUrl
      .addPath('account')
      .addPath(accountId?.toString())
      .addPath('paymentHoliday')
      .addPath('cancel')
      .build();
    return this.http.post(fullUrl, data);
  }

  public enableAutoCreditSweep(id: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('account')
      .addPath(id?.toString())
      .addPath('autoCreditSweep')
      .addPath('true')
      .build();
    return this.http.post(fullUrl, {});
  }

  public disableAutoCreditSweep(id: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('transaction')
      .addPath('account')
      .addPath(id?.toString())
      .addPath('autoCreditSweep')
      .addPath('false')
      .build();
    return this.http.post(fullUrl, {});
  }
}
