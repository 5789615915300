import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  public baseUrl: UrlBuilder = new UrlBuilder(environment.apim.baseUrl)
    .addPath(environment.apim.apiSlugs.searchApi)
    .addPathVariable('/');

  constructor(private http: HttpClient) {}

  public getAccountsSearch(): Observable<any[]> {
    const fullUrl: string = this.baseUrl.addPath('AccountsSearch').build();
    return this.http.get<any[]>(fullUrl);
  }

  public postAccountSearch(item): Observable<object> {
    const fullUrl = this.baseUrl.addPath('AccountsSearch').build();
    return this.http.post(fullUrl, item);
  }
}
