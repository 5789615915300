import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ReadPermissionsGuard } from './guards/read-permissions.guard';
import { RoutePaths } from '@shared/enums/routes.enums';

const routes: Routes = [
  {
    path: RoutePaths.HOME,
    loadChildren: () =>
      import('@home-module/home.module').then((m) => m.HomeModule),
    canActivate: [MsalGuard],
  },
  {
    path: RoutePaths.ACCOUNT,
    loadChildren: () =>
      import('@account-module/account.module').then((m) => m.AccountModule),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.SUPER_USER,
    loadChildren: () =>
      import('@super-user-module/super-user.module').then(
        (m) => m.SuperUserModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.THIRD_PARTY,
    loadChildren: () =>
      import('@third-parties-module/third-party.module').then(
        (m) => m.ThirdPartyModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.BASE_RATE_CHANGES,
    loadChildren: () =>
      import('@base-rate-changes-module/base-rate-changes.module').then(
        (m) => m.BaseRateChangesModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.TRANSACTION_CODES,
    loadChildren: () =>
      import('@transaction-code-module/transaction-code-module.module').then(
        (m) => m.TransactionCodeModuleModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.TARIFF_OF_CHARGES,
    loadChildren: () =>
      import('@tariff-of-charges-module/tariff-of-charges.module').then(
        (m) => m.TariffOfChargesModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.GROUP_CONFIG,
    loadChildren: () =>
      import('../feature/group-config-module/group-config.module').then(
        (m) => m.GroupConfigModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.GROUP_EXPOSURE,
    loadChildren: () =>
      import('../feature/group-exposure-module/group-exposure.module').then(
        (m) => m.GroupExposureModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.CUSTOMER_CENTRIC,
    loadChildren: () =>
      import('../feature/customer-centric-module/customer-centric.module').then(
        (m) => m.CustomerCentricModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.TRANSACTION,
    loadChildren: () =>
      import('../feature/transaction-module/transaction-module.module').then(
        (m) => m.TransactionModule
      ),
    canActivate: [MsalGuard, ReadPermissionsGuard],
  },
  {
    path: RoutePaths.SHORTCUTS,
    loadChildren: () =>
      import('../feature/landing-page-module/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    canActivate: [MsalGuard],
  },
  { path: '**', redirectTo: RoutePaths.HOME, pathMatch: 'full' },
  { path: '', redirectTo: RoutePaths.HOME, pathMatch: 'full' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
